<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} location</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="location.name"
                  :error="$v.location.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Location title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.location.name.$error">Name is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>

            <v-col cols="12">
              <v-select
                  v-model="location.type"
                  item-text="name"
                  item-value="value"
                  :items="types"
                  :error="$v.location.type.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Region <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.location.type.$error">Type is required</span>
              <span class="text-danger" v-if="errors.type">* {{ errors.type[0] }}</span>
            </v-col>

            <v-col cols="12" v-if="currentUser.access_type!='score'">
              <v-select
                  label="SCORE"
                  v-model="location.score_id"
                  item-text="name"
                  item-value="id"
                  :items="scores"
                  outlined
                  dense
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="location.is_active"
                  :label="location.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            x-large
            text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import LocationService from "@/services/practical-exam/location/LocationService";
import ScoreService from "@/services/score/score/ScoreService";

const location = new LocationService();
const score = new ScoreService();

export default {
  name: "CreateAndUpdate",
  validations: {
    location: {
        name: { required },
        type: { required },
        is_active: { required }
    }
  },
  data() {
    return{
      loading: false,
      currentUser:{},
      dialog: false,
      edit: false,
      title: '',
      errors: [],
      scores: [],
      types: [
        { name: 'None', value: 'none' },
        { name: 'Metro', value: 'metro' },
        { name: 'Regional', value: 'regional' },
        { name: 'Online', value: 'online' },
      ],
      location: {
        name: '',
        type: '',
        score_id: '',
        is_active: true,
    }
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();

  },
  methods:{
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    createLocation() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editLocation(location) {
      this.title = 'Edit';
      this.dialog = true;
      this.edit = true;
      this.location = location;
    },
    getAllScore() {
      score.getScores().then(response => {
        this.scores = response.data.scores;
      });
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      location
          .create(this.location)
          .then(response => {
            this.$snotify.success("Location added");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    update: function () {
      location
          .update(this.location.id, this.location)
          .then((response) => {
            this.$snotify.success("Location updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.location = {
        name: '',
        type: '',
        score_id: '',
        is_active: true,
      };
    },
  },
}
</script>

<style scoped>
  #logo_preview{
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
</style>