<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Locations</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Locations
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="createLocation()"
                    class="mt-1 btn btn-block btn-primary"
                    style="color:#fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add location
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Location"
                        v-model="search.name"
                        outlined
                        dense  v-on:keyup.enter="searchLocations" clearable
                    ></v-text-field>
                  </v-col>
                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      label="SCORE"
                      v-model="search.score_id"
                      item-text="name"
                      item-value="id" :items="scores"
                      outlined dense v-on:keyup.enter="searchLocations" clearable>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                  <v-col cols="12"  md="2">
                    <v-autocomplete
                        label="Region"
                        v-model="search.type"
                        item-text="name"
                        item-value="value"
                        :items="types" :menu-props="{ bottom: true, offsetY: true }"
                        outlined v-on:keyup.enter="searchLocations" clearable
                        dense
                    >
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="2">
                    <v-select
                        label="Status"
                        v-model="search.is_active"
                        outlined
                        item-text="name" v-on:keyup.enter="searchLocations" clearable
                        item-value="value"
                        :items="status"
                        dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 5 : 3" class="text-right">
                  <v-btn :loading="isLoading"
                      @click.prevent="searchLocations"
                         class="btn btn-primary"
                      style="color: #fff;"
                  >
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                  </v-btn>
                  </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3">Location</th>
                    <th class="px-3">Region</th>
                    <th class="px-3">SCORE</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>

                <template>
                    <draggable
                      v-model="locations"
                      @change="sortLocations"
                      @start="drag=true"
                      tag="tbody"
                      @end="drag=false"
                    >
                      <tr v-for="location in locations" :key="location.id" >
                        <td class="px-3">
                          <a @click="editLocation(location)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                            <i class="fa fa-sort"></i> {{ location.name }}
                          </a>
                        </td>
                        <td class="text-left">
                            {{ location.type_text }}
                        </td>
                        <td class="text-left">
                            {{ location.score ? location.score.name : '--' }}
                        </td>
                        <td>
                          <span class="badge"
                                  v-bind:class="{ 'badge-success': location.is_active, 'badge-danger': !location.is_active }"
                            >{{ location.is_active ? 'Active' : 'Inactive' }}</span>
                        </td>
                        <td class="pr-0 text-center">
                          <template>
                            <b-dropdown
                                size="sm"
                                variant="link"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                no-caret
                                right
                                no-flip
                            >
                              <template v-slot:button-content>
                                <i class="ki ki-bold-more-hor"></i>
                              </template>
                              <!--begin::Navigation-->
                              <div class="navi navi-hover min-w-md-250px">
                                <b-dropdown-text tag="div" class="navi-item">
                                  <a @click="editLocation(location)" class="navi-link">
                                    <span class="navi-icon">
                                        <i class="fas fa-edit"></i>
                                    </span>
                                    <span class="navi-text">Edit</span>
                                  </a>
                                </b-dropdown-text>
                                <b-dropdown-text tag="div" class="navi-item">
                                  <a class="navi-link" @click.prevent="deleteLocation(location.id)">
                                    <span class="navi-icon">
                                        <i class="fas fa-trash"></i>
                                    </span>
                                    <span class="navi-text">Delete</span>
                                  </a>
                                </b-dropdown-text>
                              </div>
                            </b-dropdown>
                          </template>
                        </td>
                      </tr>
                      <tr v-if="locations.length == 0" >
                        <td colspan="5" class="text-center"><strong>No Data Found</strong></td>
                      </tr>
                    </draggable>
                </template>
              </table>
              <b-pagination
                v-if="locations.length > 0"
                class="pull-right mt-7"  :disabled="isLoading"
                @input="getAllLocations"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>

              <v-overlay :value="isSorting">
                <v-progress-circular
                  indeterminate
                  color="dark"
                ></v-progress-circular>
              </v-overlay>

            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllLocations"></create-and-update>
    </div>  
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/practical-exam/location/CreateAndUpdate";
import LocationService from "@/services/practical-exam/location/LocationService";
import ScoreService from "@/services/score/score/ScoreService";
import draggable from 'vuedraggable';

const location = new LocationService();
const score = new ScoreService();

export default {
  name: "Index",
  components: {CreateAndUpdate, draggable},
  data() {
    return{
      drag: false,
      isSorting: false,
      isLoading: false,
      total: null,
      perPage: null,
      page: null,
      locations: [],
      currentUser:{},
      scores: [],
      sort: {
        key: '',
        isAsc: false
      },
      search:{
        name: '',
        type: '',
        score_id: '',
        is_active: ''
      },
      types: [
        { name: 'Metro', value: 'metro'},
        { name: 'Regional', value: 'regional'},
        { name: 'Online', value: 'online'},
      ],
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
    }
  },

  mounted(){
    this.getAllLocations();
    this.getAllScore();
    this.getCurrentUser();
  },
  computed: {
    sortedItems() {
      const list = this.locations.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllLocations(){
      this.isLoading = true;
      location
      .paginate(this.search, this.page)
      .then((response) => {
        this.locations = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.isLoading = false;
      })
      .catch((err) => {

      });
    },
    getAllScore() {
      score
          .getScores()
          .then(response => {
        this.scores = response.data.scores;
      });
    },
    searchLocations(){
      this.getAllLocations();
    },
    createLocation(){
      this.$refs['create-and-update'].createLocation();
    },
    editLocation(location){
      this.$refs['create-and-update'].editLocation(location);
    },
    deleteLocation(id){
      this.$confirm({
        message: `Are you sure? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            location
            .delete(id)
            .then((response) => {
                this.getAllLocations()
                this.$snotify.success("Location deleted");
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong");
            });
          }
        }
      });
    },
    sortLocations(){
      this.isSorting = true;
      location
      .sort(this.locations)
      .then(response => {
        this.getAllLocations();
        this.isSorting = false;
        this.$snotify.success('Location Sorted!!');
      })
      .catch(err => {
        this.isSorting = false;
        this.$snotify.error("Oops something went wrong");
      });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>